import React from 'react';
import { useTranslate } from '@hooks';
import { appRoles } from '@dotone/react-ui-core/constants';
import { LayoutProtected as Layout } from '@layouts';
import { Row, Col } from 'reactstrap';
import {
  AccountOverview,
  PerformanceStats,
  Exposure,
  CommissionAndBalance,
  RecentConversions,
  FeedRecentList,
  TopPublishers,
  TotalVisitors,
  MonthlyStat,
  RecentAffiliateList,
} from '@components';

const IndexPage = ({ loading, ...otherProps }) => {
  const { t } = useTranslate('dashboard', 'pages.index');

  return (
    <Layout breadcrumbsHidden title={t('title')} loading={loading} {...otherProps}>
      {!loading && (
        <div data-tour="dashboard">
          <MonthlyStat justify="end" />
          <Row data-tour="feed">
            <Col md={6}>
              <FeedRecentList role={appRoles.NETWORK} />
            </Col>
            <Col md={6}>
              <FeedRecentList role={appRoles.AFFILIATE} />
            </Col>
          </Row>
          <div data-tour="account-overview">
            <AccountOverview />
          </div>
          <div data-tour="performance-stats">
            <PerformanceStats />
          </div>
          <div data-tour="exposure">
            <Exposure />
          </div>
          <div data-tour="commission-balance">
            <CommissionAndBalance />
          </div>
          <div data-tour="recent-conversions">
            <RecentConversions />
          </div>
          <Row>
            <Col md={6} data-tour="top-publisher">
              <TopPublishers />
            </Col>
            <Col md={6} data-tour="recent-publisher">
              <RecentAffiliateList />
            </Col>
          </Row>
          <TotalVisitors />
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;
